<template>
  <CContainer class="min-vh-100" style="background-color: #ffffff;">
    <CRow>
      <CCard class="text-center text-dark col-md-5 mt-4" body-wrapper>
        <h4 class="font-weight-normal text-left">รับคูปองเรียบร้อยแล้ว</h4>
        <hr />

        <p class="text-left text-grey">
          <em class="fa fa-smile-o"></em> คะแนนคงเหลือ : {{ yourpoint }} คะแนน
        </p>
        <p class="text-left text-grey">
          <em class="fa fa-calendar-check-o"></em> วันที่รับคูปอง :
          {{ date }}
        </p>
        <CCard>
          <CCardBody color="light">
            <h5>
              {{ coupon.title }}
            </h5>
            <p class="text-description">{{ coupon.description }}</p>
            <div>
              <h5 class="text-dark mt-1">
              รหัสคูปอง : {{ data.redemptionCode }}
              </h5>
              <vue-qrcode
              class="img-fluid"
              :value="qrcodeData"
              style="width:300px"
            />
            </div>
            <br />

            <div class="text-description" >
              ส่วนลด {{ couponValue }} บาท
            </div>
            <div style="font-size:12px" class="text-description">
              หมดอายุ {{ couponEndDate }}
            </div>
          </CCardBody>
        </CCard>
        <hr />
        <div v-if="isUsed === false">
          <p class="text-center text-description">
            กรุณาแสดงคูปองนี้ให้แก่ร้านค้าเพื่อใช้สิทธิ์
          </p>

          <CButton color="success" block @click="useCoupon" size="lg">
            ใช้คูปอง
          </CButton>
        </div>
        <div v-else-if="isUsed === true">
          <CButton color="danger" block disabled @click="useCoupon" size="lg">
            คูปองถูกใช้งานแล้ว
          </CButton>
        </div>
      </CCard>
    </CRow>
  </CContainer>
</template>

<script>
import crm from '@/services/crm'
import pos from '@/services/poscrm'
import liff from '@line/liff'
import moment from 'moment'
import VueQrcode from 'vue-qrcode'
import util from '@/util/util'

export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      data: {},
      member: {},
      yourpoint: 0,
      coupon: '',
      image: '',
      isUsed: true,
      qrcodeData: '',
      couponValue: 0,
      date: moment(new Date())
        .format('LLL'),
    }
  },
  computed: {
    uid() {
      return this.$route.params.uid
    },
    couponObjectId() {
      return this.$route.params.couponObjectId
    },
    userId() {
      return this.$route.query.userId
    },
    redemptionObjectId() {
      return this.$route.query.redemptionObjectId
    },
    couponEndDate() {
      const coupon = this.coupon
      const redeemData = this.data
      if (coupon != null && coupon.dueDuration) {
        return moment(redeemData.created_at).add(coupon.dueDuration, 'm').format('DD MMM YYYY / HH:mm')
      } else if (this.coupon.endAt == undefined || this.coupon.endAt === '') {
        return 'ไม่จำกัดระยะเวลา'
      } else {
        return moment(this.coupon.endAt).format('DD/MM/YYYY HH:mm:ss')
      }
    },
  },
  async created() {
    await this.getRedemption()
    await this.getMemberByUserId()
  },
  methods: {
    async getMemberByUserId() {
      const uid = this.uid
      const userId = this.userId
      try {
        const res = await pos.get('/api/v1.0/member/' + uid + '/getbyuserId/' + userId)
        const data = res.data.data || {}
        this.member = data.documents || {}
        this.memberObjectId = this.member.objectId
        this.yourpoint = util.convertNumber(this.member.point || 0)
      } catch (error) {
        console.log(error)
      }
    },
    async getRedemption() {
      const objectId = this.redemptionObjectId
      try {
        const res = await crm.get('/api/v1.0/getredemptionbyobjectId/' + objectId)
        const resData = res.data || {}
        const error = resData.error || {}
        if (error.code === 0) {
          const data = resData.data || {}
          this.data = data.documents || {}
          this.coupon = this.data.Coupon
          this.title = this.coupon.title
          this.image = this.data.imgUrl
          this.isUsed = this.data.isUsed
          this.date = moment(data.created_at).format('LLL') || moment().format('LLL')
          
          const redemptionCode = this.data.redemptionCode
          const value = this.data.value
          this.qrcodeData = this.redemptionObjectId + '|' + redemptionCode + '|' + value
          this.couponValue = this.data.value
          document.title = this.title
        }
      } catch (error) {
        console.log(error)
      }
    },
    async useCoupon() {
      const objectId = this.redemptionObjectId
      try {
        const res = await crm.post('/api/v1.0/usecoupon/' + objectId)
        this.isUsed = true
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
